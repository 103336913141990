<template>
  <b-card
    ref="userAccounts"
    no-body
    class="p-1"
  >
    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <div
        class="demo-spacing-0 m-1 d-flex justify-content-between align-items-center"
        dir="rtl"
      >
        <template>
          <b-button
            variant="primary"
            @click="isActive=true"
          >
            <feather-icon icon="FilterIcon" />
            فیلتر
          </b-button>
        </template>
      </div>

      <search-and-filter
        :is-active.sync="isActive"
        :options="columns"
        @filter="getData(1, perPage)"
        @reset="getData(1, perPage)"
      />

      <!--    table-->
      <div class="relative-over-x">
        <b-table
          ref="refUserListTable"
          :small="true"
          responsive
          primary-key="id"
          :fields="columns"
          :items="items"
          show-empty
          empty-text="اطلاعاتی یافت نشد"
          style="white-space: nowrap; min-height : 235px"
          no-local-sort
        >
          <template #cell(account_status)="data">
            <b-badge
              pill
              :variant="'light-'+authVariant(data.item.account_status)"
              class="text-capitalize"
            >
              {{ authLabel(data.item.account_status) }}
            </b-badge>
          </template>

          <template #cell(confirm_accounts)="data">
            <b-badge
              pill
              variant="light-info"
              class="text-capitalize"
            >
              {{ data.item.confirm_accounts }}
            </b-badge>
          </template>

          <template #cell(action)="{item}">

            <b-dropdown
              id="dropdown-offset"
              variant="link"
              no-caret
              offset="80px"
              :right="true"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item :to="{ name: 'show-user', params: { id: item.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">جزییات</span>
              </b-dropdown-item>

              <b-dropdown-item>
                <feather-icon
                  icon="MessageSquareIcon"
                  badge-classes="badge-info test-badge"
                />
                <span class="align-middle ml-50">ارسال پیام</span>
              </b-dropdown-item>

              <b-dropdown-item>
                <feather-icon icon="ShoppingBagIcon" />
                <span class="align-middle ml-50">سفارشات</span>
              </b-dropdown-item>

              <!--            <b-dropdown-item>-->
              <!--              <feather-icon icon="BriefcaseIcon" />-->
              <!--              <span class="align-middle ml-50">معاملات</span>-->
              <!--            </b-dropdown-item>-->

            </b-dropdown>

          </template>
        </b-table>
      </div>

      <!-- pagination -->
      <div
        class="demo-spacing-0 d-flex justify-content-between m-1"
      >
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="rows"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          align="left"
          @input="getUsers(currentPage,perPage)"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
        <div>
          <v-select
            id="perpage"
            v-model="perPage"
            dir="rtl"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
            style="min-width: 85px"
            @input="getData(perPage)"
          />
          <label for="perpage">تعداد در صفحه</label>
        </div>
        <div />
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BButton, BPagination, BBadge, BDropdown, BDropdownItem, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import axiosIns from '@/libs/axios'
import SearchAndFilter from '@/layouts/components/SearchAndFilter.vue'

export default {
  components: {
    BCard, BTable, BButton, BPagination, BBadge, BDropdown, BDropdownItem, BOverlay, SearchAndFilter, vSelect,
  },
  data() {
    return {
      items: [],
      columns: [
        {
          label: 'نام',
          key: 'first_name',
          sortable: false,
          searchType: 'text',
        },
        {
          label: 'نام خانوادگی',
          key: 'last_name',
          sortable: false,
          searchType: 'text',
        },
        {
          label: 'ایمیل',
          key: 'email',
          sortable: false,
          searchType: 'text',
        },
        {
          label: 'تلفن همراه',
          key: 'mobile',
          sortable: false,
          searchType: 'text',
        },
        {
          label: 'تاریخ ساخت اکانت',
          key: 'created_at',
          sortable: true,
          searchType: 'date',
        },
        {
          label: 'موجودی کیف پول',
          key: 'tomman_inventory',
          sortable: true,
          searchType: 'number',
        },
        // {
        //   label: 'مجموع معاملات انجام شده',
        //   key: 'totalTradesAmount',
        //   sortable: true,
        //   searchType: 'number',
        // },
        {
          label: 'احراز هویت',
          key: 'account_status',
          sortable: true,
          searchType: 'select',
          selectOptions: [
            { label: 'تکمیل نشده', value: 'not_complete' },
            { label: 'در انتظار تایید', value: 'processing' },
            { label: 'تایید شده', value: 'confirm' },
            { label: 'تایید نشده', value: 'reject' },
          ],
        },
        {
          label: 'حساب های تایید شده',
          key: 'confirm_accounts',
          // sortable: true,
          // searchType: 'number'
        },
        // {
        //   label: 'وضعیت',
        //   key: 'isActive',
        //   sortable: true,
        //   searchType: 'select',
        //   selectOptions: [
        //     { label: 'غیر فعال', value: false },
        //     { label: 'فعال', value: true },
        //   ],
        // },
        {
          label: 'درخواست ها',
          key: 'orders',
        },
        {
          label: 'مدیریت',
          key: 'action',
        },
      ],
      currentPage: null,
      perPage: null,
      rows: null,
      perPageOptions: [5, 10, 20, 50, 100],
      loading: false,
      isActive: false,
    }
  },
  computed: {
    authVariant() {
      const a = {
        not_complete: 'secondary',
        reject: 'danger',
        processing: 'warning',
        confirmed: 'success',
        FULL_AUTHORIZED: 'success',
        CONFIRMED_IDENTITY: 'info',
        UNIDENTIFIED: 'danger',
        INFORMATION_CHANGED_PENDING: 'warning',
        INFORMATION_CHANGED: 'secondary',
      }
      return e => a[e]
    },
    authLabel() {
      const a = {
        not_complete: 'تکمیل نشده',
        reject: 'تایید نشده',
        processing: 'در انتظار تایید',
        confirmed: 'تایید شده',
        FULL_AUTHORIZED: 'تایید شده کامل',
        CONFIRMED_IDENTITY: 'هوبت تایید شده',
        UNIDENTIFIED: 'هوبت تایید نشده',
        INFORMATION_CHANGED_PENDING: 'در انتظار تایید',
        INFORMATION_CHANGED: 'تکمیل نشده',
      }
      return e => a[e]
    },
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    async getUsers(currentPage, perPage) {
      this.loading = true
      const queryParams = {
        page: currentPage,
        per_page: perPage,
      }
      const response = await this.$axios('/admin-panel/users', {
        params: queryParams,
      })
      this.items = response.data.data
      this.rows = response.data.pagination.total_pages
      this.perPage = response.data.pagination.per_page
      this.currentPage = response.data.pagination.current_page
      this.loading = false
    },
    async getData(perPage) {
      this.loading = true

      // const queryParams = {
      //   page,
      //   ...this.$route.query,
      // }

      // if (this.$route.name === 'show-accounts') {
      //   queryParams = {
      //     ...queryParams,
      //     inviterId: Number(this.$route.params.id),
      //   }
      // }

      const response = await this.$axios(
        '/admin-panel/users',
        {
          params: {
            per_page: perPage,
          },
        },
      )

      this.items = response.data.data
      this.rows = response.data.pagination.total_pages
      this.perPage = response.data.pagination.per_page
      this.currentPage = response.data.pagination.current_page
      this.loading = false
    },
  },
}
</script>

<style>

</style>
