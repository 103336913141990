<template>
  <b-sidebar
    id="search-and-filter-sidebar"
    :visible="isActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          فیلتر
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="Filter"
        @reset.prevent="resetValue"
        @input="checkParam"
        @change="checkParam"
      >

        <b-form-group
          v-for="(option,index) in options"
          v-show="option.searchType"
          :key="`${index} ${keyValue}`"
          :label="option.label"
          :style="{'--prepend-width':notMatch[index] ? '40px':'75px'}"
        >
          <!--          <div-->
          <!--            slot="label"-->
          <!--            class="bv-no-focus-ring col-form-label py-0 d-flex justify-content-between"-->
          <!--          >-->
          <!--            {{ option.label }}-->
          <!--            <b-form-checkbox-->
          <!--              v-if="option.searchType === 'date'"-->
          <!--              v-model="notMatch[index]"-->
          <!--              switch-->
          <!--              inline-->
          <!--              class="mr-0"-->
          <!--              @change="changeMatch(option.key,notMatch[index],index)"-->
          <!--            />-->
          <!--          </div>-->

          <!--          <b-input-group-->
          <!--            v-if="!['text','select'].includes(option.searchType)"-->
          <!--            class="my-50"-->
          <!--          >-->

          <!--            <b-form-input-->
          <!--              readonly-->
          <!--              :value="notMatch[index] ? 'از':'مطابق با'"-->
          <!--              style="maxWidth: var(&#45;&#45;prepend-width)"-->
          <!--              class="text-center"-->
          <!--            />-->

          <!--            <cleave-->
          <!--              v-if="option.searchType==='number'"-->
          <!--              v-model="queryFilter[option.key+'From']"-->
          <!--              class="form-control"-->
          <!--              :raw="false"-->
          <!--              :options="cleaveOptions.number"-->
          <!--              placeholder=" مقدار ..."-->
          <!--              @input="parsValue(option.key+'From')"-->
          <!--            />-->

          <!--            <date-picker-->
          <!--              v-else-->
          <!--              v-model="queryFilter[option.key+'From']"-->
          <!--              format="YYYY-MM-DDTHH:mm:ss"-->
          <!--              display-format="dddd jDD jMMMM jYYYY"-->
          <!--              input-class="form-control"-->
          <!--              placeholder="تاریخ ..."-->
          <!--              clearable-->
          <!--              :max="queryFilter[option.key+'To']"-->
          <!--              @change="checkParam"-->
          <!--            />-->

          <!--          </b-input-group>-->
          <!--          <b-input-group v-if="!['text','select'].includes(option.searchType) && notMatch[index]">-->

          <!--            <b-form-input-->
          <!--              readonly-->
          <!--              value="تا"-->
          <!--              style="max-width: var(&#45;&#45;prepend-width)"-->
          <!--              class="text-center"-->
          <!--            />-->

          <!--            <cleave-->
          <!--              v-if="option.searchType==='number'"-->
          <!--              v-model="queryFilter[option.key+'To']"-->
          <!--              class="form-control"-->
          <!--              :raw="false"-->
          <!--              :options="cleaveOptions.number"-->
          <!--              placeholder="مقدار ..."-->
          <!--              @input="parsValue(option.key+'To')"-->
          <!--            />-->

          <!--            <date-picker-->
          <!--              v-else-->
          <!--              v-model="queryFilter[option.key+'To']"-->
          <!--              format="YYYY-MM-DDTHH:mm:ss"-->
          <!--              display-format="dddd jDD jMMMM jYYYY"-->
          <!--              input-class="form-control"-->
          <!--              placeholder="تاریخ .."-->
          <!--              clearable-->
          <!--              :min="queryFilter[option.key+'From']"-->
          <!--              @change="checkParam"-->
          <!--            />-->

          <!--          </b-input-group>-->

          <v-select
            v-if="option.searchType==='select'"
            v-model="queryFilter[option.key]"
            dir="rtl"
            :multiple="false"
            :options="option.selectOptions"
            :reduce="val => val.value"
            clearable
            input-id="user-role"
            style="min-width:105px"
            @input="checkParam"
          />

          <b-form-input
            v-if="option.searchType==='text'"
            :id="option.key"
            v-model="queryFilter[option.key]"
            trim
            :placeholder="option.label"
          />
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            فیلتر
          </b-button>
          <b-button
            v-ripple.400="'rgba(199,186,186,0.15)'"
            type="reset"
            variant="outline-danger"
            class="mr-2"
          >
            حذف فیلتر
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            بازگشت
          </b-button>
        </div>

      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  // BInputGroup,
  // BFormCheckbox,
  // BInputGroupPrepend,
  // BInputGroupAppend,
  // BDropdown,
  // BDropdownItem
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'
// import Cleave from 'vue-cleave-component'

export default {
  name: 'SearchAndFilter',
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    // BInputGroup,
    // BFormCheckbox,
    // BInputGroupPrepend,
    // BInputGroupAppend,
    // BDropdown,
    // BDropdownItem,
    vSelect,
    // Cleave,
  },
  model: {
    prop: 'isActive',
    event: 'update:is-active',
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userData: [],
      selectValue: [],
      fromValue: [],
      toValue: [],
      queryFilter: {},
      keyValue: 0,
      date: '',
      number: '',
      selected: '',
      cleaveOptions: {
        // date: {
        //     // date: true,
        //     blocks: [4, 2, 2],
        //     delimiter: '-',
        //     delimiterLazyShow: true
        //     // dateMin: '2000-01-01',
        //     // dateMax: '2099-12-31',
        //     // datePattern: ['Y', 'm', 'd'],
        // },
        number: {
          numeral: true,
        },
      },
      notMatch: [],
    }
  },
  mounted() {
    this.resetForm()
    this.getQuery()
    this.checkParam()
  },
  methods: {
    parsValue(e) {
      this.queryFilter[e] = this.queryFilter[e].replace(/,/ig, '')
    },
    getQuery() {
      this.queryFilter = { ...this.$route.query }
    },
    checkParam() {
      // for (const e in this.queryFilter) if (typeof this.queryFilter[e] !== 'boolean' && (!this.queryFilter[e] || this.queryFilter[e] == '0')) delete this.queryFilter[e]
    },
    resetForm() {
      // let index = 0
      // for (const e of this.options) {
      //   if (['date', 'number'].includes(e.searchType)) {
      //     this.queryFilter[`${e.key}From`] = ''
      //     this.queryFilter[`${e.key}To`] = ''
      //     this.notMatch[index] = true
      //   } else this.queryFilter[e.key] = ''
      //   index++
      // }
    },
    resetValue() {
      this.resetForm()
      this.queryFilter = {}
      this.$router.push({ query: '' })
      this.$emit('reset')
    },
    changeMatch(key, value, index) {
      if (!value) {
        this.toValue[index] = ''
        this.setParam(`${key}To`, this.toValue[index])
      }
      this.setParam(`${key}IsMatch`, !value)
    },
    setParam(key, value) {
      if (typeof value === 'boolean' || value.length) {
        this.queryFilter[key] = typeof value !== 'boolean' ? value.replace(/,/ig, '') : value
      } else {
        delete this.queryFilter[key]
      }
    },
    Filter() {
      this.checkParam()
      this.$router.replace({ query: this.queryFilter }).catch(() => {})
      console.log(this.$route.query)
      this.$emit('filter')
    },
  },
}
</script>

<style lang="scss">
    #add-new-user-sidebar {
        .vs__dropdown-menu {
            max-height: 200px !important;
        }
    }

    .vpd-main {
        width: calc(100% - var(--prepend-width));

        .vpd-input-group {
            label {
                display: none;
            }

            .form-control {
                border-left: 0;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    /*.input-group-prepend {*/
    /*    .v-select {*/
    /*        .vs__dropdown-toggle {*/
    /*            border-top-right-radius: 0;*/
    /*            border-bottom-right-radius: 0;*/
    /*            padding-bottom: 0;*/
    /*            height: 100%;
            /*}*/
    /*}*/
    /*}*/
</style>
